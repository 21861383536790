import { graphql } from "gatsby";
import React, { useState } from "react";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from "~/components/Layout";
import { ArticleRenderer } from '../components/ArticleRenderer';
import DateFormat from "../components/elements/date";
import { ButtonArrow } from "../components/elements/buttons/Button";
import { ModalDialog } from "../components/elements/ModalDialog";
import { Link } from "gatsby";

export default function roleSingle({
    data: { wpRole, wpPage, wp },
  }
){

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout wp={wp}>
      <Seo post={wpRole} />
      <section className="section-lrg !max-w-[1200px] grid grid-cols-12 pt-28 mb-4 md:pt-[232px] md:pb-[108px]">
        <div className="col-span-12 md:col-span-10 md:col-start-3">
          <h6 className="font-body t-18 mb-6 md:mb-[28px] flex space-x-4"><span className="text-coral block">Posted</span><span className="block"><DateFormat dateString={wpRole.date} /></span></h6>
          <h1 className="text-h2 max-w-[570px]">{wpRole?.postTypeRoles?.mastheadTitle}</h1>
        </div>
      </section>
      <section className="section-contained xl:!w-[1360px] case-study-details font-body section-top section-bot bg-white rounded-3xl p-6 lg:py-26 lg:px-20">
        <div className="flex flex-col md:flex-row gap-y-10 md:gap-y-0 md:space-x-12 lg:space-x-20 relative">
          <div className="md:max-w-[320px] lg:max-w-[260px]">
            <div className="sticky top-[4.5rem]">
              <ul className="grid grid-cols-2 gap-4 lg:block md:mb-[46px]">
                <li className="mb-8 col-span-2 md:col-span-1">
                  <h3 className="uppercase t-12-bold mb-2">Job Role</h3>
                  <span>{wpRole?.postTypeRoles?.shortSummary}</span>
                </li>
                <li className="mb-8 col-span-2 md:col-span-1">
                  <h3 className="uppercase t-12-bold mb-2">Location</h3>
                  <span>{wpRole?.postTypeRoles?.location}</span>
                </li>
                <li className="mb-8 col-span-2 md:col-span-1">
                  <h3 className="uppercase t-12-bold mb-2">Department</h3>
                  <span>{wpRole?.postTypeRoles?.department}</span>
                </li>
                {wpRole?.postTypeRoles?.salary &&
                  <li className="mb-8 col-span-2 md:col-span-1">
                    <h3 className="uppercase t-12-bold mb-2">Salary</h3>
                    <span>$ {wpRole?.postTypeRoles?.salary}</span>
                  </li>
                }
                <div className="mb-8 col-span-2">
                  {wpRole?.postTypeRoles?.keyPoints && (
                    <>
                      <h3 className="uppercase t-12-bold mb-4">Key Points</h3>
                      <div className="bullet-points">
                        <ul>
                          {wpRole?.postTypeRoles?.keyPoints.map(( item, i) => (
                            <li className="t-15" key={i}>{item.listItem}</li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </ul>
              <button onClick={() => setIsOpen(true)}  className="btn btn-coral flex items-center space-x-[9px] whitespace-nowrap justify-center mx-auto w-full">
                <span>Apply now</span>
                <ButtonArrow color={'coral'} arrowFill={false}/>
              </button>
              <ModalDialog role={wpRole?.postTypeRoles?.mastheadTitle} isOpen={isOpen} setIsOpen={setIsOpen} form={'apply'} />
            </div>
          </div>
          <article className="flex-auto">
            <ArticleRenderer
              prefix="Role_Posttyperoles_ArticleContent_"
              content={wpRole?.postTypeRoles?.articleContent}
            />
          </article>
        </div>
      </section>
      <div className="container text-center mt-8 md:mt-32">
        <Link
          className="flex max-w-[200px] mx-auto items-center space-x-4  group justify-center z-10 transition-all hover:opacity-50 duration-200 t-14 sm:text-base text-darkGreen font-medium"
          to='/careers/'
        >
            <span>Back to careers page</span>
            <span className="group-hover:translate-x-4 transition-all duration-200">
              <ButtonArrow arrowFill={true} />
            </span>
        </Link>
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Role($id: String!) {
    wpRole(id: { eq: $id }) {
        title
        uri
        date
        postTypeRoles {
          shortSummary
          department
          location
          salary
          keyPoints {
            listItem
          }
          mastheadTitle
          articleContent {
            ... on WpRole_Posttyperoles_ArticleContent_Text {
              fieldGroupName
              text
            }
            ... on WpRole_Posttyperoles_ArticleContent_Quote {
              fieldGroupName
              text
              position
              cite
              logo {
                altText
                localFile {
                  publicURL
                }
              }
            }
            ... on WpRole_Posttyperoles_ArticleContent_Image {
              fieldGroupName
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
            ... on WpRole_Posttyperoles_ArticleContent_Bullet {
              fieldGroupName
              heading
              list{
                fieldGroupName
                listItem
              }
            }
            ... on WpRole_Posttyperoles_ArticleContent_Statistics {
              fieldGroupName
              statisticItem {
                number
                description
              }
            }
          }
        }
    }
    wp {
      ...GeneratedWp
      header {
        optionsHeader {
          menu {
            link {
              url
              title
            }
            showSubmenu
            submenu {
              type
              withPostMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                    description
                  }
                }
              }
              withImageMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                rightColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                image {
                  ...Image
                }
              }
            }
          }
        }
      }
    }
  }
`;
